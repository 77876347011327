import { VBtn } from 'vuetify/lib/components/VBtn';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "background-color": "var(--white)",
        "z-index": "2",
        "min-width": "400px",
        "max-width": "500px",
        height: "100%",
        overflow: "hidden",
      },
    },
    [
      _c("div", { staticClass: "conversation" }, [
        _c(
          "div",
          { staticClass: "pa-3" },
          [
            _c("h2", { staticStyle: { color: "var(--primary)" } }, [
              _vm._v(_vm._s(_vm.$tc("notification", 2))),
            ]),
            _vm._l(_vm.Notifications.Notifications, function (n) {
              return _c("div", { key: "item-" + n.Id, staticClass: "my-2" }, [
                _c(
                  "a",
                  {
                    staticStyle: { display: "flex" },
                    on: {
                      click: function ($event) {
                        return _vm.redirect(n)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "my-auto py-4 px-3" },
                      [
                        _c(VRow, { staticClass: "mx-0" }, [
                          _c(
                            "p",
                            {
                              staticClass: "my-auto",
                              staticStyle: {
                                "margin-bottom": "0px",
                                color: "var(--dark)",
                              },
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.gs.convert_date(n.Timestamp, false)
                                  )
                                ),
                              ]),
                              _vm._v(
                                " - " +
                                  _vm._s(_vm.get_notification_text(n)) +
                                  " "
                              ),
                              _c("span", { staticClass: "cta" }, [
                                _vm._v(_vm._s(_vm.get_cta_text(n))),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          VRow,
                          { staticClass: "mx-0", attrs: { align: "center" } },
                          [
                            _c(VSpacer),
                            n.Type ==
                            _vm.notificationsTypeEnum.ALLOCATION_REQUEST
                              ? _c(
                                  "div",
                                  { staticStyle: { display: "inline-flex" } },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: {
                                          display: "contents",
                                          height: "100%",
                                          width: "100%",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.respond(n, 1)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "menu_category",
                                            staticStyle: {
                                              border:
                                                "1px solid var(--primary)",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "mx-2 my-1" },
                                              [_vm._v("ACEITAR")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticStyle: {
                                          display: "contents",
                                          height: "100%",
                                          width: "100%",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.respond(n, 0)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "menu_category",
                                            staticStyle: {
                                              color: "gray",
                                              border: "1px solid gray",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ttCommonsBold mx-2 my-1",
                                              },
                                              [_vm._v("NEGAR")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("hr", { staticClass: "linha" }),
              ])
            }),
            _vm.Notifications.Pager.CurrentPage <
            _vm.Notifications.Pager.EndPage
              ? _c(
                  VRow,
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      VBtn,
                      {
                        attrs: {
                          text: "",
                          color: "primary",
                          height: "15",
                          loading: _vm.Loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("load_more")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "text-transform": "none" } },
                          [_vm._v(_vm._s(_vm.$t("see_more")))]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }