import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category-card" },
    [
      _c(
        "a",
        {
          staticStyle: { display: "flex" },
          attrs: { "data-test": "CompanyCard:Header" },
          on: {
            click: function ($event) {
              _vm.Expanded = !_vm.Expanded
            },
          },
        },
        [
          _vm.Category.length > 0
            ? _c("h4", { staticClass: "pipeline-header" }, [
                _vm._v(" " + _vm._s(_vm.Title) + " "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "var(--primary)",
                      "margin-left": "4px",
                    },
                  },
                  [_vm._v("(" + _vm._s(_vm.Category.length) + ")")]
                ),
              ])
            : _vm._e(),
          _c(VSpacer),
          _c(VIcon, { attrs: { large: "", color: "primary" } }, [
            _vm._v(
              _vm._s(_vm.Expanded ? "mdi-chevron-up" : "mdi-chevron-down")
            ),
          ]),
        ],
        1
      ),
      _c(VExpandTransition, [
        _vm.Expanded
          ? _c("div", { staticClass: "mt-6" }, [
              _c(
                "div",
                { staticClass: "company-row" },
                _vm._l(_vm.Category, function (company) {
                  return _c(
                    "div",
                    {
                      key: company.CompanyId,
                      staticClass: "company-div",
                      attrs: {
                        "data-companyid": company.CompanyId,
                        "data-test": "CompanyCard:Company",
                      },
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticClass: "company-card",
                          attrs: {
                            width: "" + (_vm.gs.isMobile() ? "auto" : "350px"),
                          },
                        },
                        [
                          company.Banner
                            ? _c(VImg, {
                                staticClass: "v-img",
                                staticStyle: { "border-radius": "8px" },
                                attrs: {
                                  width: "350",
                                  height: "150",
                                  src: _vm.gs.get_photo_path(company.Banner),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.video_click(company)
                                  },
                                },
                              })
                            : _c(VAvatar, {
                                staticClass: "v-img",
                                staticStyle: { "border-radius": "8px" },
                                attrs: {
                                  width: "350",
                                  height: "150",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.video_click(company)
                                  },
                                },
                              }),
                          _c(
                            "div",
                            {
                              staticStyle: { height: "125px", display: "flex" },
                            },
                            [
                              company.Logo
                                ? _c(VImg, {
                                    staticClass: "company-logo",
                                    attrs: {
                                      "max-height": "105px",
                                      "max-width": "105px",
                                      contain: "",
                                      src: _vm.gs.get_photo_path(company.Logo),
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "px-3 mt-1",
                                  staticStyle: {
                                    height: "100%",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c(
                                    "h1",
                                    {
                                      staticClass: "company-name",
                                      attrs: {
                                        "data-test": "CompanyCard:CompanyName",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(company.Name) + " ")]
                                  ),
                                  _vm.Title != _vm.$t("portfolio_companies")
                                    ? _c(
                                        "h6",
                                        { staticClass: "company-status" },
                                        [
                                          _c(VAvatar, {
                                            staticClass: "mb-1 mr-2",
                                            attrs: {
                                              color: "primary",
                                              size: "12",
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("open_offer")) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "h6",
                                        { staticClass: "company-status" },
                                        [
                                          _c(VAvatar, {
                                            staticClass: "mb-1 mr-2",
                                            attrs: { color: "red", size: "12" },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("closed_offer")) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                  company.ReservedQuotas >= 50
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "company-quota mb-0 mt-1",
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticStyle: {
                                                color: "var(--primary)",
                                                "font-size": "22px",
                                              },
                                              attrs: {
                                                id: "company-quote-porcentagens",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$t(
                                                      "reserved_quotas_briefing",
                                                      {
                                                        value:
                                                          company.ReservedQuotas,
                                                      }
                                                    )
                                                    .split("%")[0]
                                                ) + "%"
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .$t(
                                                  "reserved_quotas_briefing",
                                                  {
                                                    value:
                                                      company.ReservedQuotas,
                                                  }
                                                )
                                                .split("%")[1]
                                            ) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    VRow,
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "company-profile-btn",
                                          attrs: {
                                            text: "",
                                            color: "primary",
                                            "data-test":
                                              "Alpha:CompanyCard:BtnSeeProfile:" +
                                              company.CompanyId,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.go_to_company_briefing(
                                                company.CompanyId,
                                                company.Briefing.Id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("see_profile")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _vm.dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                VContainer,
                {
                  style: _vm.gs.isMobile() && "background-color: var(--white)",
                  attrs: { fluid: "", tag: "section" },
                },
                [
                  _c(
                    VCard,
                    {
                      class: _vm.gs.isMobile() ? "" : "dxa_modal",
                      style: !_vm.gs.isMobile() && "padding-bottom: 15px",
                    },
                    [
                      _c("div", { staticClass: "embed-container" }, [
                        _c("iframe", {
                          attrs: {
                            src:
                              "https://player.vimeo.com/video/" +
                              _vm.selectedVideo +
                              "?portrait=0&byline=0&title=0",
                            frameborder: "0",
                            allowfullscreen: "",
                          },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }