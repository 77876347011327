import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VCard,
        {
          staticClass: "thesis-card mb-0 mt-3",
          staticStyle: { "border-radius": "8px" },
          attrs: { width: (_vm.gs.isMobile() ? 250 : 350) + "px" },
        },
        [
          _vm.bannerUrl
            ? _c(VImg, {
                staticStyle: { "border-radius": "8px 8px 0px 0px" },
                attrs: {
                  height: "150",
                  src: _vm.gs.get_photo_path(_vm.bannerUrl),
                },
                on: {
                  click: function ($event) {
                    _vm.dialog = true
                  },
                },
              })
            : _c(VAvatar, {
                staticStyle: { "border-radius": "8px 8px 0px 0px !important" },
                attrs: {
                  tile: "",
                  width: "100%",
                  height: "150",
                  color: "primary",
                },
                on: {
                  click: function ($event) {
                    _vm.dialog = true
                  },
                },
              }),
          _c(
            "div",
            {
              staticClass: "px-3 mt-2 thesis-card-grid",
              class: { "thesis-card-onGrid": _vm.Thesis.logo },
            },
            [
              _c("div", [
                _vm.Thesis.logo
                  ? _c("img", {
                      staticClass: "thesis-card-logo",
                      attrs: {
                        width: "60",
                        height: "60",
                        src: _vm.gs.get_photo_path(_vm.Thesis.logo),
                        alt: "Logo",
                      },
                    })
                  : _vm._e(),
              ]),
              _c("div", [
                _c("p", { staticClass: "pipeline-card-header dark-color" }, [
                  _vm._v(" " + _vm._s(_vm.Thesis.name) + " "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "pipeline-card-text-box",
                    class: [
                      !_vm.overviewText && _vm.gs.isMobile()
                        ? "pipeline-card-text-box-noMargin"
                        : "",
                    ],
                    staticStyle: { height: "32px" },
                  },
                  [
                    _c("p", { staticClass: "pipeline-card-text" }, [
                      _vm._v(" " + _vm._s(_vm.overviewText) + " "),
                    ]),
                  ]
                ),
              ]),
              _c(
                VBtn,
                {
                  staticClass: "details-btn",
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.redirect_details },
                },
                [_vm._v(_vm._s(_vm.$t("details_click")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                VContainer,
                {
                  style: _vm.gs.isMobile() && "background-color: var(--white)",
                  attrs: { fluid: "", tag: "section" },
                },
                [
                  _c(
                    VCard,
                    {
                      class: _vm.gs.isMobile() ? "" : "dxa_modal",
                      style: !_vm.gs.isMobile() && "padding-bottom: 15px",
                    },
                    [
                      _c("div", { staticClass: "embed-container" }, [
                        _c("iframe", {
                          attrs: {
                            src:
                              "https://player.vimeo.com/video/" +
                              _vm.gs.check_field(_vm.Thesis.introVideo) +
                              "?portrait=0&byline=0&title=0",
                            frameborder: "0",
                            allowfullscreen: "",
                          },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }