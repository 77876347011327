import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isInvestor ? _c("SignContractBanner") : _vm._e(),
      _c(
        VContainer,
        {
          class: _vm.gs.isMobile() ? "px-0" : "",
          attrs: { id: "pipeline", fluid: "", tag: "section" },
        },
        [
          _vm.loading
            ? _c(
                VRow,
                {
                  staticClass: "pa-4 thesis-div-card",
                  attrs: { justify: "center", align: "center" },
                },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", size: "35", color: "primary" },
                  }),
                ],
                1
              )
            : _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      VTabs,
                      {
                        staticClass: "pipeline-header-tabs-container",
                        attrs: { color: "primary" },
                        on: { change: _vm.handleGoMarket },
                        model: {
                          value: _vm.pipelineTab,
                          callback: function ($$v) {
                            _vm.pipelineTab = $$v
                          },
                          expression: "pipelineTab",
                        },
                      },
                      [
                        _c(VTab, [
                          _c(
                            "div",
                            { staticClass: "tab tab-pipeline" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "icon icon-pipeline",
                                attrs: { icon: "fa-solid fa-user-circle" },
                              }),
                              _c(
                                "span",
                                { staticClass: "label label-pipeline" },
                                [_vm._v(_vm._s(_vm.$t("pipeline")))]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.accessMarket
                          ? _c(VTab, [
                              _c(
                                "div",
                                { staticClass: "tab" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon icon-market",
                                    attrs: { icon: "fa-solid fa-sliders" },
                                  }),
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(_vm.$t("market_app_label"))),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.theses &&
                _vm.theses.length > 0 &&
                _vm.envThesesCard === "FALSE"
                  ? _c("div", { staticClass: "thesis-div-card" }, [
                      _c("h4", { staticClass: "pipeline-header" }, [
                        _c(
                          "b",
                          {
                            attrs: { "data-test": "Alpha:Pipeline:TxtThesis" },
                          },
                          [_vm._v(_vm._s(_vm.$tc("thesis", 2)))]
                        ),
                      ]),
                      _c("div", { staticClass: "pipeline-row" }, [
                        _c("table", [
                          _c(
                            "tr",
                            _vm._l(_vm.theses, function (thesis) {
                              return _c(
                                "td",
                                {
                                  key: thesis.id,
                                  class: [
                                    _vm.gs.isMobile() &&
                                      "pipeline-row-startMobile",
                                  ],
                                },
                                [
                                  _c("ThesisCard", {
                                    attrs: { Thesis: thesis },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticStyle: { width: "100%" } }, [
                  _vm.emptyPipeline
                    ? _c("div", { staticClass: "empty-pipeline" }, [
                        _c("p", { staticClass: "text" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("empty_pipeline_text")) + " "
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "link",
                            on: {
                              click: function () {
                                return _vm.$router.push("/dashboard/investor")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("dashboard_investidor")))]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _vm.comanagementCompanies.length > 0
                        ? _c("CoManagerOpportunities", {
                            attrs: {
                              Title: _vm.$t("ongoing_opts"),
                              Category: _vm.comanagementCompanies,
                              Expanded: true,
                            },
                          })
                        : _vm._e(),
                      _vm.exclusiveOpportunities.length > 0
                        ? _c("ExclusiveOpportunities", {
                            attrs: {
                              Title: _vm.$t("exclusive_opportunities"),
                              Category: _vm.exclusiveOpportunities,
                              Expanded: true,
                              "data-test": "Pipeline:ExclusiveCompanies",
                            },
                          })
                        : _vm._e(),
                      _vm.highlightedCompanies.length > 0
                        ? _c("HighlightedCompanies", {
                            attrs: {
                              Title: _vm.$t("selected_for_you"),
                              Category: _vm.highlightedCompanies,
                              Expanded: _vm.exclusiveOpportunities.length < 1,
                              "data-test": "Pipeline:HighlightedCompanies",
                            },
                          })
                        : _vm._e(),
                      _vm.ongoingOpportunities.length > 0
                        ? _c("OngoingOpportunities", {
                            attrs: {
                              Title: _vm.$t("ongoing_opts"),
                              Category: _vm.ongoingOpportunities,
                              Expanded:
                                _vm.exclusiveOpportunities.length < 1 &&
                                _vm.highlightedCompanies.length < 1,
                              "data-test": "Pipeline:OngoingCompanies",
                            },
                          })
                        : _vm._e(),
                      _vm.portfolioCompanies.length > 0
                        ? _c("PortfolioCompanies", {
                            attrs: {
                              Title: _vm.$t("portfolio_companies"),
                              Category: _vm.portfolioCompanies,
                              Expanded: false,
                              "data-test": "Pipeline:PortfolioCompanies",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                VContainer,
                { attrs: { fluid: "", tag: "section" } },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "dxa_modal",
                      staticStyle: { "padding-bottom": "15px" },
                    },
                    [
                      _c("div", { staticClass: "embed-container" }, [
                        _c("iframe", {
                          attrs: {
                            src:
                              "https://player.vimeo.com/video/" +
                              _vm.selectedVideo +
                              "?portrait=0&byline=0&title=0",
                            frameborder: "0",
                            allowfullscreen: "",
                          },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        VDialog,
        {
          staticClass: "ma-0",
          staticStyle: { display: "flex" },
          model: {
            value: _vm.signContractDialog,
            callback: function ($$v) {
              _vm.signContractDialog = $$v
            },
            expression: "signContractDialog",
          },
        },
        [
          _c(
            VCard,
            {
              staticClass: "pa-5",
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "justify-items": "center",
                "max-width": "500px",
                "text-align": "center",
              },
            },
            [
              _c(
                "span",
                { staticStyle: { display: "flex", "text-align": "center" } },
                [_vm._v(_vm._s(_vm.$t("bloqued_user")))]
              ),
              _c(
                VRow,
                { staticClass: "ma-0" },
                [
                  _c(
                    VCol,
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                      },
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "secondary ma-0",
                          staticStyle: { "min-width": "120px" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/auth")
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("log_out")))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "primary ma-0",
                          staticStyle: {
                            "min-width": "120px",
                            display: "flex",
                            "text-align": "center",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("continue")))])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }