import { VImg } from 'vuetify/lib/components/VImg';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VListGroup,
    {
      attrs: {
        group: _vm.group,
        "prepend-icon": _vm.item.icon,
        "sub-group": _vm.subGroup,
        "append-icon": "mdi-menu-down",
        color:
          _vm.barColor !== "rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)"
            ? "white"
            : "grey darken-1",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _vm.text
                ? _c(VListItemIcon, {
                    staticClass: "v-list-item__icon--text",
                    domProps: { textContent: _vm._s(_vm.computedText) },
                  })
                : _vm.item.avatar
                ? _c(
                    VListItemAvatar,
                    {
                      staticClass: "align-self-center",
                      attrs: { color: "grey" },
                    },
                    [
                      _c(VImg, {
                        attrs: {
                          src: "https://demos.creative-tim.com/material-dashboard-pro/assets/img/faces/avatar.jpg",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VListItemContent,
                [
                  _c(VListItemTitle, {
                    domProps: { textContent: _vm._s(_vm.item.title) },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._l(_vm.children, function (child, i) {
        return [
          child.children
            ? _c("base-item-sub-group", {
                key: "sub-group-" + i,
                attrs: { item: child },
              })
            : _c("base-item", {
                key: "item-" + i,
                attrs: { item: child, text: "" },
              }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }