import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VAlert,
    _vm._g(
      _vm._b(
        {
          staticClass: "v-alert--material",
          attrs: { dark: "" },
          scopedSlots: _vm._u(
            [
              _vm.$attrs.icon
                ? {
                    key: "prepend",
                    fn: function () {
                      return [
                        _c(
                          VIcon,
                          {
                            staticClass: "v-alert__icon elevation-6 white",
                            attrs: { light: "", color: _vm.$attrs.color },
                          },
                          [_vm._v(" " + _vm._s(_vm.$attrs.icon) + " ")]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              _vm.$attrs.dismissible
                ? {
                    key: "close",
                    fn: function (ref) {
                      var toggle = ref.toggle
                      return [
                        _c(
                          VBtn,
                          {
                            attrs: {
                              "aria-label":
                                _vm.$vuetify.lang.t("$vuetify.close"),
                              color: "",
                              icon: "",
                              small: "",
                            },
                            on: { click: toggle },
                          },
                          [_c(VIcon, [_vm._v(" $vuetify.icons.cancel ")])],
                          1
                        ),
                      ]
                    },
                  }
                : null,
            ],
            null,
            true
          ),
        },
        "v-alert",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }