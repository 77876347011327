import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBtn,
    {
      staticClass: "teste",
      attrs: {
        loading: _vm.loading,
        disabled: _vm.disabled,
        id: _vm.outline ? "new-dxa-button-outline" : "new-dxa-button",
      },
      on: { click: _vm.handleClick },
    },
    [_vm._v(_vm._s(_vm.title))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }