import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMain,
    [_c("router-view", { key: _vm.$route.path, staticClass: "mainRender" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }