import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "add-investor-modal", fluid: "", tag: "section" } },
    [
      _c(
        VCard,
        {
          staticClass: "MobileScreen",
          staticStyle: {
            width: "100% !important",
            "max-width": "100% !important",
          },
        },
        [
          _c(
            VCardTitle,
            {
              staticClass: "mb-2",
              staticStyle: { "padding-top": "0 !important" },
            },
            [
              _c(
                VRow,
                { staticStyle: { float: "right" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "black", small: "", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _c(
                        VIcon,
                        {
                          staticStyle: { position: "static" },
                          attrs: { small: "" },
                        },
                        [_vm._v("mdi-close-thick")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticStyle: { display: "block" },
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "dxa_modal_title text-center",
                      staticStyle: {
                        color: "var(--dark) !important",
                        "font-size": "20px",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.carousel + 1) +
                          " - " +
                          _vm._s(
                            _vm.$t(
                              "onboarding_" + (_vm.carousel + 1) + "_title"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            VCardText,
            [
              _c(
                VCarousel,
                {
                  attrs: { height: "360", "hide-delimiters": "" },
                  model: {
                    value: _vm.carousel,
                    callback: function ($$v) {
                      _vm.carousel = $$v
                    },
                    expression: "carousel",
                  },
                },
                _vm._l(_vm.slides, function (slide, i) {
                  return _c(
                    VCarouselItem,
                    { key: i, staticClass: "text-center" },
                    [
                      _c(VImg, {
                        staticClass: "mx-auto",
                        attrs: { src: _vm.gs.get_photo_path(slide) },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                VItemGroup,
                {
                  staticClass: "text-center",
                  attrs: { mandatory: "" },
                  model: {
                    value: _vm.carousel,
                    callback: function ($$v) {
                      _vm.carousel = $$v
                    },
                    expression: "carousel",
                  },
                },
                _vm._l(_vm.length, function (n) {
                  return _c(VItem, {
                    key: "btn-" + n,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var active = ref.active
                            var toggle = ref.toggle
                            return [
                              _c(
                                VBtn,
                                {
                                  attrs: { "input-value": active, icon: "" },
                                  on: { click: toggle },
                                },
                                [
                                  active
                                    ? _c(
                                        VIcon,
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-record")]
                                      )
                                    : _c(VIcon, [_vm._v("mdi-record")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
              _c(
                "p",
                {
                  staticStyle: { color: "var(--dark)", "font-weight": "bold" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("onboarding_" + (_vm.carousel + 1) + "_text")
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.carousel == 1
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        color: "var(--dark)",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "onboarding_" + (_vm.carousel + 1) + "_text_2"
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c(
            VCard,
            {
              staticClass: "DesktopScreen",
              staticStyle: {
                width: "70% !important",
                "max-width": "90% !important",
              },
            },
            [
              _c(
                VCardTitle,
                {
                  staticClass: "mb-2",
                  staticStyle: { "padding-top": "0 !important" },
                },
                [
                  _c(
                    VRow,
                    { staticStyle: { float: "right" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { color: "black", small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticStyle: { position: "static" },
                              attrs: { small: "" },
                            },
                            [_vm._v("mdi-close-thick")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    {
                      staticStyle: { display: "block" },
                      attrs: { align: "center", justify: "center" },
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "dxa_modal_title text-center",
                          staticStyle: {
                            color: "var(--dark) !important",
                            "font-size": "20px",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.carousel + 1) +
                              " - " +
                              _vm._s(
                                _vm.$t(
                                  "onboarding_" + (_vm.carousel + 1) + "_title"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                VCardText,
                [
                  _c(
                    VCarousel,
                    {
                      attrs: { height: "545", "hide-delimiters": "" },
                      model: {
                        value: _vm.carousel,
                        callback: function ($$v) {
                          _vm.carousel = $$v
                        },
                        expression: "carousel",
                      },
                    },
                    _vm._l(_vm.slides, function (slide, i) {
                      return _c(
                        VCarouselItem,
                        { key: i, staticClass: "text-center" },
                        [
                          _c(VImg, {
                            staticClass: "my-auto",
                            attrs: { src: _vm.gs.get_photo_path(slide) },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    VItemGroup,
                    {
                      staticClass: "text-center",
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.carousel,
                        callback: function ($$v) {
                          _vm.carousel = $$v
                        },
                        expression: "carousel",
                      },
                    },
                    _vm._l(_vm.length, function (n) {
                      return _c(VItem, {
                        key: "btn-" + n,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var active = ref.active
                                var toggle = ref.toggle
                                return [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        "input-value": active,
                                        icon: "",
                                      },
                                      on: { click: toggle },
                                    },
                                    [
                                      active
                                        ? _c(
                                            VIcon,
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-record")]
                                          )
                                        : _c(VIcon, [_vm._v("mdi-record")]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "var(--dark)",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("onboarding_" + (_vm.carousel + 1) + "_text")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.carousel == 1
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "var(--dark)",
                            "font-weight": "bold",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "onboarding_" + (_vm.carousel + 1) + "_text_2"
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c(
            VCard,
            {
              staticClass: "minorDesktopScreen",
              staticStyle: {
                width: "70% !important",
                "max-width": "90% !important",
              },
            },
            [
              _c(
                VCardTitle,
                {
                  staticClass: "mb-2",
                  staticStyle: { "padding-top": "0 !important" },
                },
                [
                  _c(
                    VRow,
                    { staticStyle: { float: "right" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { color: "black", small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticStyle: { position: "static" },
                              attrs: { small: "" },
                            },
                            [_vm._v("mdi-close-thick")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    {
                      staticStyle: { display: "block" },
                      attrs: { align: "center", justify: "center" },
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "dxa_modal_title text-center",
                          staticStyle: {
                            color: "var(--dark) !important",
                            "font-size": "20px",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.carousel + 1) +
                              " - " +
                              _vm._s(
                                _vm.$t(
                                  "onboarding_" + (_vm.carousel + 1) + "_title"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                VCardText,
                [
                  _c(
                    VCarousel,
                    {
                      attrs: { height: "470", "hide-delimiters": "" },
                      model: {
                        value: _vm.carousel,
                        callback: function ($$v) {
                          _vm.carousel = $$v
                        },
                        expression: "carousel",
                      },
                    },
                    _vm._l(_vm.slides, function (slide, i) {
                      return _c(
                        VCarouselItem,
                        { key: i, staticClass: "text-center" },
                        [
                          _c(VImg, {
                            staticClass: "my-auto",
                            attrs: { src: _vm.gs.get_photo_path(slide) },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    VItemGroup,
                    {
                      staticClass: "text-center",
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.carousel,
                        callback: function ($$v) {
                          _vm.carousel = $$v
                        },
                        expression: "carousel",
                      },
                    },
                    _vm._l(_vm.length, function (n) {
                      return _c(VItem, {
                        key: "btn-" + n,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var active = ref.active
                                var toggle = ref.toggle
                                return [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        "input-value": active,
                                        icon: "",
                                      },
                                      on: { click: toggle },
                                    },
                                    [
                                      active
                                        ? _c(
                                            VIcon,
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-record")]
                                          )
                                        : _c(VIcon, [_vm._v("mdi-record")]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "var(--dark)",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("onboarding_" + (_vm.carousel + 1) + "_text")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.carousel == 1
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "var(--dark)",
                            "font-weight": "bold",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "onboarding_" + (_vm.carousel + 1) + "_text_2"
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }