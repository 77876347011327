import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { staticClass: "error-page fill-height", attrs: { tag: "section" } },
    [
      _c(
        VRow,
        { staticClass: "text-center", attrs: { justify: "center" } },
        [
          _c(
            VCol,
            { attrs: { cols: "auto" } },
            [
              _c("h1", { staticClass: "title font-weight-black" }, [
                _vm._v("404"),
              ]),
              _c("div", { staticClass: "display-3 mb-5 mt-10" }, [
                _vm._v(" " + _vm._s(_vm.$t("page_not_found")) + " "),
              ]),
              _c(
                VBtn,
                {
                  staticClass: "secondary",
                  attrs: { depressed: "", to: "/companies" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("go_back")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }