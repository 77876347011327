var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "mb-12 text-center" }, [
    _c("h1", {
      staticClass: "font-weight-light mb-2 headline",
      domProps: { textContent: _vm._s("Vuetify " + _vm.heading) },
    }),
    _c("span", { staticClass: "font-weight-light subtitle-1" }, [
      _vm._v(" Please checkout the "),
      _c(
        "a",
        {
          staticClass: "secondary--text",
          staticStyle: { "text-decoration": "none" },
          attrs: {
            href: "https://vuetifyjs.com/" + _vm.link,
            rel: "noopener",
            target: "_blank",
          },
        },
        [_vm._v(" full documentation ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }