import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show_banner
    ? _c(
        VAlert,
        {
          attrs: { color: "orange" },
          scopedSlots: _vm._u(
            [
              {
                key: "prepend",
                fn: function () {
                  return [
                    _c(
                      VIcon,
                      {
                        staticClass: "mr-3",
                        attrs: { color: "white", large: "" },
                      },
                      [_vm._v("mdi-alert-octagon-outline")]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1504369922
          ),
        },
        [
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _c(VCol, { attrs: { cols: "12", md: "10" } }, [
                _c("div", { staticClass: "textPart my-auto" }, [
                  _c(
                    "span",
                    {
                      staticStyle: { color: "var(--white)" },
                      style: _vm.isMobile()
                        ? "font-size: 15px;"
                        : "font-size: 17px;",
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("sign_contract_alert")) + " ")]
                  ),
                ]),
              ]),
              _c(VCol, { attrs: { cols: "12", md: "2" } }, [
                _c(
                  "div",
                  { staticClass: "buttonPart" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "mr-4",
                        attrs: { color: "white", outlined: "" },
                        on: { click: _vm.openDocusign },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "textButton",
                            style: _vm.isMobile() ? "font-size: 11px;" : "",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("sign_contract", 1)) + " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }