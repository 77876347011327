import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-material-card",
    _vm._g(
      _vm._b(
        {
          staticClass: "v-card--material-stats",
          attrs: { icon: _vm.icon },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("div", { staticClass: "ml-auto text-right" }, [
                    _c("div", {
                      staticClass: "body-3 grey--text font-weight-light",
                      domProps: { textContent: _vm._s(_vm.title) },
                    }),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "display-2 font-weight-light text--primary",
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.value) + " "),
                        _c("small", [_vm._v(_vm._s(_vm.smallValue))]),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "base-material-card",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        VCol,
        { staticClass: "px-0", attrs: { cols: "12" } },
        [_c(VDivider)],
        1
      ),
      _c(
        VIcon,
        {
          staticClass: "ml-2 mr-1",
          attrs: { color: _vm.subIconColor, size: "16" },
        },
        [_vm._v(" " + _vm._s(_vm.subIcon) + " ")]
      ),
      _c("span", {
        staticClass: "caption grey--text font-weight-light",
        class: _vm.subTextColor,
        domProps: { textContent: _vm._s(_vm.subText) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }