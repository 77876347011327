// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/NeurialGrotesk-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/NeurialGrotesk-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/NeurialGrotesk-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/fonts/Segoe-UI.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@/assets/fonts/SourceSansPro-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("@/assets/fonts/SourceSansPro-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("@/assets/fonts/SourceSansPro-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("@/assets/fonts/SourceSans3-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("@/assets/fonts/SourceSans3-Light.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
exports.push([module.id, "\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n  font-family: \"Neurial Grotesk\";\n}\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");\n  font-family: \"Neurial Grotesk Medium\";\n}\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"opentype\");\n  font-family: \"Neurial Grotesk Bold\";\n}\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n  font-family: \"Segoe UI\";\n  font-style: normal;\n  font-weight: normal;\n}\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  font-family: \"Source Sans Pro\";\n}\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n  font-family: \"Source Sans Pro SemiBold\";\n}\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");\n  font-family: \"Source Sans Pro Bold\";\n}\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ");\n  font-family: \"Source Sans 3\";\n}\n@font-face {\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n  font-family: \"Source Sans 3 Light\";\n}\n.v-btn {\n  text-transform: none !important;\n}\n@media only screen and (max-width: 600px) {\n.Vue-Toastification__container .Vue-Toastification__toast {\n    width: 90% !important;\n    margin: 15px;\n    border-radius: 8px;\n}\n}\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-family: \"Source Sans Pro\";\n  font-weight: normal;\n}\n", ""]);
// Exports
module.exports = exports;
