import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VNavigationDrawer,
    _vm._b(
      {
        key: _vm.expandOnHover,
        staticClass: "dxa-navigation-drawer",
        attrs: {
          id: "core-navigation-drawer",
          dark:
            _vm.barColor !== "rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)",
          "expand-on-hover": _vm.expandOnHover,
          "mini-variant": _vm.mini,
          right: _vm.$vuetify.rtl,
          "mobile-breakpoint": "960",
          app: "",
          "mini-variant-width": "100",
          width: "260",
          color: "dark",
        },
        on: {
          "update:miniVariant": function ($event) {
            _vm.mini = $event
          },
          "update:mini-variant": function ($event) {
            _vm.mini = $event
          },
        },
        model: {
          value: _vm.drawer,
          callback: function ($$v) {
            _vm.drawer = $$v
          },
          expression: "drawer",
        },
      },
      "v-navigation-drawer",
      _vm.$attrs,
      false
    ),
    [
      _c(
        VListItem,
        { attrs: { "two-line": "" } },
        [
          _c(
            VListItemContent,
            [
              _c(
                VListItemTitle,
                {
                  staticClass: "text-uppercase font-weight-regular display-2",
                  staticStyle: { display: "flex" },
                },
                [
                  !_vm.check_partner({ requiresPartner: true })
                    ? _c("div", { staticStyle: { "max-width": "170px" } }, [
                        !_vm.mini
                          ? _c("img", {
                              staticStyle: {
                                width: "100%",
                                "max-width": "170px",
                              },
                              attrs: {
                                src: require("../../assets/dealboard-branca.png"),
                                alt: "Dealboard Logo",
                              },
                            })
                          : _c("img", {
                              staticClass: "navigationLogo",
                              attrs: {
                                src: require("../../assets/dealboard-square-white.svg"),
                                alt: "Dealboard Logo",
                              },
                            }),
                      ])
                    : _c("div", [
                        !_vm.mini
                          ? _c("img", {
                              staticStyle: {
                                width: "100%",
                                "max-width": "170px",
                              },
                              attrs: {
                                src: require("../../assets/dealboard-branca.png"),
                                alt: "Dealboard Logo",
                              },
                            })
                          : _c("img", {
                              staticClass: "navigationLogo",
                              attrs: {
                                src: require("../../assets/dealboard-square-white.svg"),
                                alt: "Dealboard Logo",
                              },
                            }),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(VDivider, { staticClass: "mb-1" }),
      _c(
        VList,
        { attrs: { expand: "", nav: "" } },
        [
          _c("div"),
          _c(
            VList,
            _vm._l(_vm.routesList, function (item, i) {
              return _c(
                VListItem,
                {
                  key: "item-" + i,
                  attrs: {
                    link: "",
                    to: item.to,
                    "data-test": "Drawer:" + item.title,
                  },
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c(
                    VListItemContent,
                    [
                      _c(VListItemTitle, { staticClass: "white-color" }, [
                        _vm._v(_vm._s(_vm.$tc(item.title, 2))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c("div"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }