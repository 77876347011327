import { VApp } from 'vuetify/lib/components/VApp';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VApp,
    [
      _vm.accessVueNavigation
        ? _c("dashboard-core-app-bar", {
            on: {
              open_onboarding: function ($event) {
                _vm.onboardingDialog = true
              },
            },
            model: {
              value: _vm.expandOnHover,
              callback: function ($$v) {
                _vm.expandOnHover = $$v
              },
              expression: "expandOnHover",
            },
          })
        : _vm._e(),
      _vm.accessVueNavigation
        ? _c("dashboard-core-drawer", {
            attrs: { "expand-on-hover": _vm.expandOnHover },
            on: {
              "update:expandOnHover": function ($event) {
                _vm.expandOnHover = $event
              },
              "update:expand-on-hover": function ($event) {
                _vm.expandOnHover = $event
              },
            },
          })
        : _vm._e(),
      _c("dashboard-core-view"),
      _vm.onboardingDialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.onboardingDialog,
                callback: function ($$v) {
                  _vm.onboardingDialog = $$v
                },
                expression: "onboardingDialog",
              },
            },
            [
              _c("OnboardingModal", {
                on: {
                  close: function ($event) {
                    _vm.onboardingDialog = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }